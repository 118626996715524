import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AllArticles from './pages/AllArticles';
import ContactPage from './pages/ContactPage';
import AgeVerificationModal from './components/AgeVerificationModal'; // Import the Age Verification Modal
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

function App() {
  const AGE_VERIFICATION_EXPIRATION_DAYS = 30; // Number of days before the age verification expires

  // State to track if the user has verified their age
  const [isAgeVerified, setIsAgeVerified] = useState(false);

  // Check localStorage to see if the user has already verified their age within the expiration period
  useEffect(() => {
    const ageVerified = localStorage.getItem('isAgeVerified');
    const verificationDate = localStorage.getItem('verificationDate');
    
    if (ageVerified === 'true' && verificationDate) {
      const now = new Date();
      const storedDate = new Date(verificationDate);
      
      // Calculate the difference in days between now and the stored verification date
      const differenceInDays = (now - storedDate) / (1000 * 60 * 60 * 24);
      
      if (differenceInDays <= AGE_VERIFICATION_EXPIRATION_DAYS) {
        // Within the expiration period, so the user is considered verified
        setIsAgeVerified(true);
      } else {
        // Expired, so clear the age verification data from localStorage
        localStorage.removeItem('isAgeVerified');
        localStorage.removeItem('verificationDate');
      }
    }
  }, []);

  // Handler to confirm age verification and set the verification date
  const handleAgeVerification = () => {
    const now = new Date();
    localStorage.setItem('isAgeVerified', 'true');
    localStorage.setItem('verificationDate', now.toISOString()); // Store the current date for expiration tracking
    setIsAgeVerified(true);
  };

  // Show the AgeVerificationModal if the user hasn't verified their age
  if (!isAgeVerified) {
    return <AgeVerificationModal onConfirm={handleAgeVerification} />;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-articles" element={<AllArticles />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
